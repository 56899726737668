html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Avenir', 'Helvetica Neue', Arial, sans-serif;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.App {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  box-shadow: none;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  overflow: hidden;
  max-height: 100px; /* Adjust based on your header's height */
}

.title-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 1rem; 
}

.horizon-icon {
  width: 0.98rem;  /* Approximately 30% smaller than 1.4rem */
  height: 0.98rem;
}

.title {
  font-size: 0.875rem;  /* Equivalent to 14px */
  font-weight: bold;
  color: #333;
  margin: 0;
}

.icon-title-container .canvas-title {
  font-size: 0.875rem;  /* Equivalent to 14px */
  font-weight: bold;
  color: #333;
  margin: 0;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem; /* 6px converted to rem */
  align-items: center;
  max-width: 100%;
}

.controls select {
  flex: 0 1 auto;
  min-width: 80px;
  max-width: fit-content;
  margin-right: 10px;
  padding: 4px 8px;
  font-size: calc(12px);
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  background-color: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.controls select:last-child {
  margin-right: 0;
}

.controls select:focus {
  border-color: #007bff;
}

@media (max-width: 768px) {
  .controls {
    flex-direction: row; /* Ensures horizontal alignment */
    align-items: center; /* Keeps items vertically centered */
    justify-content: flex-start; /* Aligns items to the start of the flex container */
  }

  .controls select {
    width: auto; /* Allows select elements to maintain natural width */
    margin-right: 0.625rem; /* 10px converted to rem */
    margin-bottom: 0; /* Removes bottom margin */
  }

  .controls select:last-child {
    margin-right: 0; /* Removes right margin for the last select element */
  }
}

.fps {
  font-size: calc(12px); /* Dynamic font size */
  color: #666;
  margin-left: auto;
}

.canvas-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100%);
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
}

/* Media queries for finer control */
@media (max-width: 768px) {
  .title-container {
    gap: 0.25rem;
  }

  .horizon-icon {
    width: 0.875rem;  /* Approximately 30% smaller than 1.25rem */
    height: 0.875rem;
  }

  .title {
    font-size: 0.75rem;  /* Equivalent to 12px */
  }

  .controls select, .fps {
    font-size: calc(10px); /* Adjust font size for mobile */
  }

  .fullscreen-icon {
    display: none;
  }
}

@media (min-width: 1200px) {
  .title {
    font-size: calc(14px); /* Slightly larger for large screens */
  }

  .controls select, .fps {
    font-size: calc(10px); /* Adjust font size for large screens */
  }
}

.header.collapsed {
  max-height: 0;
  padding: 0;
}

.weather-icon, .fullscreen-icon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  cursor: pointer;
}

.popup {
  position: absolute;
  top: 50px; /* Adjust as needed */
  left: 50%; /* Center horizontally relative to the viewport */
  transform: translateX(-50%); /* Adjust to center */
  background-color: #fff;
  border: 0.5px solid #ccc;
  padding: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0; /* Initially hidden */
  visibility: hidden; /* Initially hidden */
  z-index: 1000;
  border-radius: 8px; /* Added border-radius */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth transition */
}

.popup.visible {
  opacity: 1;
  visibility: visible;
}

p {
  font-size: 0.8rem; /* Set your desired font size */
  line-height: 2; /* Optional: Set line height for better readability */
  color: #333; /* Optional: Set text color */
}

.intro-text {
  text-align: center;
  color: white;
  background: rgba(254, 254, 254, 0.467);
  padding: 30px;
  border-radius: 1rem;
  border: 0.02px solid rgb(212, 212, 212); /* Ensure border is explicitly defined */
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1px; /* Added margin-bottom */
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .intro-text {
    border-bottom: 0.5px solid grey; /* Ensure bottom border is applied on iPhone */
  }
}

.intro-text h2 {
  color: rgb(37, 37, 37);
  padding-bottom: 10px;
  padding-top: 0rem; /* Add this line to create space below the icon */
}

.intro-text p {
  color: #979797;
  margin-bottom: 0; /* Remove bottom margin as we're using gap in the container */
}

.fps-full {
    display: inline;
}

@media (max-width: 767px) {
    .fps-full {
        display: none;
    }

    .intro-text {
        padding: 24px; /* Reduce padding on mobile */
        border-radius: 10; /* Optional: Remove border-radius on mobile for full-width look */
    }
}

.recent-cities-heading {
  margin-bottom: 0.7rem; /* Changed from 10px to 0.5rem */
  margin-top: 20px;
  font-size: 0.8rem;
  color: #333;
  align-self: flex-start;
}

.recent-cities {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem; /* Updated gap */
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  height: auto; /* Ensure the container height is flexible */
  background-color: #f4f4f4; /* Add background color to make borders more visible */
}

.recent-city-btn {
  background-color: rgba(255, 255, 255, 0.2);
  border: 0.0625rem solid rgba(204, 204, 204, 0.593); /* 0.0625rem equals 1px */
  border-radius: 10px;
  color: #333;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 40px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  flex-basis: 0;
  white-space: nowrap;
  box-sizing: border-box;
  text-align: left;
  /* margin-right: 10px; */ /* Ensure the same gap as recent city buttons */
  box-shadow: 0 2px 4px rgba(120, 120, 120, 0.1); /* Subtle shadow */
}

.recent-city-btn .country {
  font-size: 10px;
  color: #888;
  margin-bottom: 2px;
}

.recent-city-btn .time-ago {
  font-size: 9px;
  color: #999;
  margin-top: 2px;
}

.recent-city-btn:hover {
  background-color: rgba(234, 234, 234, 0.643);
}

.intro-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .intro-text {
    padding: 24px;
  }

  .recent-cities-heading {
    font-size: 0.8em;
  }

  .recent-city-btn {
    padding: 6px 10px;
  }

  .recent-city-btn .country {
    font-size: 8px;
  }

  .recent-city-btn .time-ago {
    font-size: 8px;
  }

  .intro-container {
    padding: 0 16px;
  }
}

.recent-cities h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
}

.horizon-icon {
  display: block;
  margin: 0 auto;
  width: 1.2rem;
  height: 1.2rem;
}

.intro-text .horizon-icon {
    display: none; /* Change this line to hide the icon */
}

.intro-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1rem; /* Reduced gap for more precise control */
    margin-bottom: 0.5rem;
    transition: all 0.3s ease;
}

.intro-select {
    width: 100%;
    padding: 10px;
    font-size: 0.9rem;
    background-color: white;
    color: #333;
    border: 0.0625rem solid #aaaaaa8d;
    border-radius: 0.6rem;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.intro-select.selected {
    background-color: #333;
    color: white;
}

.down-arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    pointer-events: none;
    transition: stroke 0.3s ease;
}

.intro-select + .down-arrow {
    stroke: #333;
}

.intro-select.selected + .down-arrow {
    stroke: white;
}

/* Optional: Style the arrow differently when the select is focused */
.intro-select:focus + .down-arrow {
    opacity: 0.8;
}

.select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    transition: all 0.3s ease;
}

.down-arrow-icon {
    position: absolute;
    right: 10px;
    pointer-events: none;
    width: 16px;
    height: 16px;
    stroke: #fff;
}

/* When there's only one select button, stretch it to full width */
.intro-buttons.single-select .intro-select {
    width: 100%;
}

/* Add this rule to ensure the select container also expands */
.intro-buttons.single-select .intro-select-container {
    width: 100%;
}

/* Styles for when only the country select is visible */
.intro-buttons:not(.country-selected) .select-wrapper:first-child {
    width: 100%;
}

/* Updated styles for when both selects are visible */
.intro-buttons.country-selected .select-wrapper {
    width: calc(50% - 0.5rem); /* Subtracting half of the gap to ensure even distribution */
}

.recent-cities-container {
    width: 100%; /* Adjust width as needed */
    max-width: 600px; /* Ensure it matches the width of the intro box */
    box-sizing: border-box; /* Ensure padding is included in the width */
}

/* Optional: Adjust the styles of the heading and cities to fit within the new container */
.recent-cities-heading {
    margin-bottom: 0.5rem; /* Adjust spacing as needed */
}

.recent-cities {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem; /* Adjust spacing between buttons */
}

/* Add new styles for the intro-button-container */
.intro-container .intro-content {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.intro-container .intro-content .intro-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem; /* Adjust gap as needed */
  box-sizing: border-box;
}

.intro-container .intro-content .intro-button-container .intro-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.intro-container .intro-content .intro-button-container .intro-buttons-container .select-location-heading {
  margin-bottom: 0.7rem; /* Changed from 10px to 0.5rem */
  margin-top: 20px;
  font-size: 0.8rem;
  color: #333;
  align-self: flex-start;
}

.intro-container .intro-content .intro-button-container .intro-buttons-container .intro-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem; /* Adjust gap as needed */
  margin-bottom: 0.5rem;
  transition: all 0.3s ease;
}

.select-location-heading {
  margin-bottom: 0.7rem; /* Changed from 10px to 0.5rem */
  margin-top: 20px;
  font-size: 0.8rem;
  color: #333;
  align-self: flex-start;
}

.icon-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem; /* Updated gap to 1 rem */
}

.canvas-title {
    font-size: 0.875rem;  /* Equivalent to 14px */
    font-weight: bold;
    color: #333;
    margin: 0;
}

.continent-label {
    font-size: 0.5rem; /* Set font size to 0.5rem */
    font-weight: bold;
}

select optgroup {
  font-size: 0.3rem; /* Set font size to 0.5rem */
  font-weight: bold;
  border: none; /* Remove border */
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
}

/* Style the select element */
select {
    appearance: none; /* Remove default styling */
    -webkit-appearance: none; /* Remove default styling for Safari */
    -moz-appearance: none; /* Remove default styling for Firefox */
    background-color: #fff; /* Background color */
    border: 1px solid #ccc; /* Border */
    padding: 10px; /* Padding */
    font-size: 16px; /* Font size */
    border-radius: 4px; /* Rounded corners */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Add a custom arrow icon */
select::after {
    content: '▼'; /* Unicode character for down arrow */
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Prevent the arrow from blocking clicks */
}

/* Style the select wrapper */
.select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}

/* Style the down arrow */
.down-arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Prevent the arrow from blocking clicks */
}

/* Remove grey lines between optgroups */
select optgroup {
    border: none; /* Remove border */
    margin: 0; /* Remove margin */
    padding: 0; /* Remove padding */
    background-color: transparent; /* Ensure background is transparent */
}

/* Remove grey lines between options */
select option {
    border: none; /* Remove border */
    margin: 0; /* Remove margin */
    padding: 0; /* Remove padding */
    background-color: transparent; /* Ensure background is transparent */
}
